import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment/moment';

@Pipe({
  name: 'dateDifference',
})
export class DateDifferencePipe implements PipeTransform {
  transform(value: moment.MomentInput, otherValue: moment.MomentInput, unit?:  moment.unitOfTime.Diff, precision?:boolean): unknown {
    const date = moment(value);
    const date2 = otherValue !== null ? moment(otherValue) : moment();

    return date.diff(date2, unit, precision);
  }
}
