import { AfterViewInit, Component, HostBinding, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Moment } from 'moment';
import { TranslateService as NGXTranslateService } from '@ngx-translate/core';
import { IFormConfig } from '@shared/interfaces/IFormConfig';
import { Router, UrlSerializer } from '@angular/router';
import { SpinnerLoadingComponent } from '@shared/components/spinner-loading/spinner-loading.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostBinding('class.vertical') @Input() vertical: boolean = false;
  @Input() slugHotel: string = '';
  @Input() langCode: string = 'en';
  @Input() selected: { startDate: Moment; endDate: Moment; minDate?: string; destination?: string };
  @HostBinding('class.page-hotel') @Input() inPageHotel: boolean = false;
  @ViewChild('searchTemplate') searchTemplate: TemplateRef<any>;
  @Input() currentView: 'search-view' | 'minified-view' = 'search-view';
  expanded: boolean = false;
  selectedPeople = {
    numAdults: {
      key: 'numAdults',
      value: 2,
      min: 1,
      max: 30,
    },
    numChildren: {
      key: 'numChildren',
      value: 0,
      min: 0,
      max: 30,
    },
  };
  formSearch: FormGroup;
  FORM_CONFIG = FORM_CONFIG;
  isOpenTemplateAddDates: boolean;
  isOpenTemplatePeople: boolean;
  locale = {
    daysOfWeek: moment.localeData(this.translateService.currentLang).weekdaysMin(),
    monthNames: moment.localeData(this.translateService.currentLang).monthsShort(),
    firstDay: moment.localeData(this.translateService.currentLang).firstDayOfWeek(),
  };

  constructor(
    private formBuilder: FormBuilder,
    private translateService: NGXTranslateService,
    private router: Router,
    private serializer: UrlSerializer,
    public dialog: MatDialog,
  ) {
  }

  ngOnDestroy(): void {
    this.dialog.closeAll();
  }

  ngOnInit(): void {
    this.dialog.closeAll();
    this.selected = {
      startDate: moment(),
      endDate: moment().add(1, 'day'),
      minDate: moment().toDate().toDateString(),
      destination: 'all',
    };
    this.formSearch = this.createFormSearch();
    if (this.langCode==='pt-pt'){
      this.langCode='pt'
    }
    this.translateService.setDefaultLang(this.langCode);
    this.locale = {
      daysOfWeek: moment.localeData(this.langCode).weekdaysMin(),
      monthNames: moment.localeData(this.langCode).monthsShort(),
      firstDay: moment.localeData(this.langCode).firstDayOfWeek(),
    }
  }

  createFormSearch(): FormGroup {
    return this.formBuilder.group({
      [this.FORM_CONFIG.checkIn.key]: new FormControl(this.selected.startDate, [
        Validators.required,
      ]),
      [this.FORM_CONFIG.checkOut.key]: new FormControl(this.selected.endDate, [
        Validators.required,
      ]),
      [this.FORM_CONFIG.numAdults.key]: new FormControl(this.selectedPeople.numAdults.value, [
        Validators.required,
      ]),
      [this.FORM_CONFIG.numChildren.key]: new FormControl(this.selectedPeople.numChildren.value, [
        Validators.required,
      ]),
    });
  }

  ngAfterViewInit(): void {
  }

  openPeopleTemplate() {
    this.isOpenTemplatePeople = !this.isOpenTemplatePeople;
  }

  choseDate($event: any) {
    if (
      moment(new Date($event.startDate)).format('ll') ===
      moment(new Date($event.endDate)).format('ll')
    ) {
      return;
    }
    this.isOpenTemplateAddDates = !this.isOpenTemplateAddDates;
    this.selected = {
      ...this.selected,
      startDate: moment(new Date($event.startDate)),
      endDate: moment(new Date($event.endDate)),
    };
    this.formSearch.get('checkIn').setValue(moment(new Date($event.startDate)));
    this.formSearch.get('checkOut').setValue(moment(new Date($event.endDate)));
  }

  counter(key: string, type: 'add' | 'subtract') {
    if (type === 'add') {
      if (
        this.selectedPeople[key].value >= this.selectedPeople[key].min &&
        this.selectedPeople[key].value < this.selectedPeople[key].max
      ) {
        this.selectedPeople[key].value++;
      }
    }
    if (type === 'subtract') {
      if (
        this.selectedPeople[key].value > this.selectedPeople[key].min &&
        this.selectedPeople[key].value <= this.selectedPeople[key].max
      ) {
        this.selectedPeople[key].value--;
      }
    }
    this.formSearch.get(key).setValue(this.selectedPeople[key].value);
  }

  submitForm() {
    if (this.formSearch.valid) {
      const value = this.formSearch.value;
      const checkIn: Moment = value.checkIn;
      const checkOut: Moment = value.checkOut;
      const tree = this.router.createUrlTree(['hotel', this.slugHotel], {
        queryParams: {
          ...value,
          checkIn: checkIn.format('YYYY-MM-DD'),
          checkOut: checkOut.format('YYYY-MM-DD'),
          lang: this.langCode,
        },
      });
      //this.openLoadingDialog();
      window.location.href = 'https://booking.lostambos.com.pe' + this.serializer.serialize(tree);
    }
  }

  openLoadingDialog(): void {
    const dialogRef = this.dialog.open(SpinnerLoadingComponent);
    dialogRef.disableClose = true;
  }

  openDialogSearch() {
    this.expanded = !this.expanded;
  }
}

export const FORM_CONFIG = {
  destination: <IFormConfig>{
    label: {
      defaultValue: 'Destination',
      translateKey: 'booking.form.hotel.destination.label',
    },
    iconName: 'location_on',
    key: 'destination',
  },
  checkIn: <IFormConfig>{
    label: {
      defaultValue: 'Check in',
      translateKey: 'booking.form.hotel.checkIn.label',
    },
    key: 'checkIn',
    iconName: 'date_range',
  },
  checkOut: <IFormConfig>{
    label: {
      defaultValue: 'checkOut',
      translateKey: 'booking.form.hotel.checkOut.label',
    },
    key: 'checkOut',
    iconName: 'date_range',
  },
  numAdults: <IFormConfig>{
    label: {
      defaultValue: 'Adults',
      translateKey: 'booking.form.hotel.numAdults.label',
    },
    key: 'numAdults',
    iconName: 'person',
  },
  numChildren: <IFormConfig>{
    label: {
      defaultValue: 'Children',
      translateKey: 'booking.form.hotel.numChildren.label',
    },
    key: 'numChildren',
    iconName: 'person',
  },
};

export interface ISearch {
  destination: string;
  checkIn: moment.Moment;
  checkOut: moment.Moment;
  numAdults: number;
  numChildren: number;
}
