import { Pipe, PipeTransform } from '@angular/core';
import {List, ListIterator, ValueIteratee, maxBy, NotVoid} from 'lodash';

@Pipe({
  name: 'maxBy'
})
export class MaxByPipe implements PipeTransform {
  // transform maxBy lodash function
  transform<T>(collection: List<T>, iteratee?: ValueIteratee<T>): T | undefined {
    return maxBy(collection, iteratee);
  }
}
