import { Pipe, PipeTransform } from '@angular/core';
import {
  List,
  ListIterator,
  Many,
  NotVoid,
  orderBy,
} from 'lodash';
@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

  transform<T> (
    collection: List<T> | null | undefined,
    iterates?: Many<ListIterator<T, NotVoid>>,
    orders?: Many<boolean | 'asc' | 'desc'>,
  ): T[] {
    return orderBy(collection, iterates, orders);
  }

}
