import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { BrowserModule } from '@angular/platform-browser';
import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SearchComponent } from '@shared/components/elements/search/search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@shared/material/material.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { TranslatesBrowserModule } from '@shared/translates/translates-browser';
import { TranslatesService } from '@shared/translates';
import { initLanguage } from './app/app.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslatesElementsLoaderService } from '@shared/translates/translates-elements/translates-elements-loader.service';
import { RouterModule } from '@angular/router';
import { NgxWebstorageModule } from 'ngx-webstorage';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    PipesModule,
    HttpClientModule,
    RouterModule.forRoot([]),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useClass: TranslatesElementsLoaderService,
        deps:[HttpClient]
      }
    }),
    NgxWebstorageModule.forRoot(),
    NgxDaterangepickerMd.forRoot(),
  ],
  declarations: [SearchComponent],
  providers: [{provide: APP_BASE_HREF, useValue: '/'}],
  entryComponents: [],
})
class ElementsModule {
  constructor(private injector: Injector) {
  }

  ngDoBootstrap() {
    const search = createCustomElement(SearchComponent, { injector: this.injector });
    customElements.define('pms-search', search);
  }
}

platformBrowserDynamic().bootstrapModule(ElementsModule)
  .catch(err => console.error(err));
