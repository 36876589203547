import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pluralize'
})
export class PluralizePipe implements PipeTransform {
  transform(value: number | unknown, singular: string, plural: string = ''): string {
    if (!singular) {
      throw new Error('Singular word is required for pluralize pipe.');
    }
    if (value === 1) {
      return `1 ${singular}`;
    } else {
      return `${value} ${plural || singular + 's'}`;
    }
  }
}
