import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calculateAmount'
})
export class CalculateAmountPipe implements PipeTransform {

  transform(items: any[], attr: string, attrRate: string): any {
    return items.reduce((a, b) => (a + (b[attr]*b[attrRate])), 0);
  }

}
