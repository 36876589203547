import { HttpClient } from '@angular/common/http';
import { makeStateKey, StateKey } from '@angular/platform-browser';

import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

export class TranslatesElementsLoaderService implements TranslateLoader {
  constructor(
    private http: HttpClient,
  ) {
  }

  public getTranslation(lang: string): Observable<any> {
    const key: StateKey<number> = makeStateKey<number>(`transfer-translate-${lang}`);
    console.log(key);
    return this.http.get(`https://app.lostambos.com.pe/api/booking-engine/translates/${lang}?populate=translations`);
  }
}
