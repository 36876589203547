import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'lodash';

@Pipe({
  name: 'map',
})
export class MapPipe implements PipeTransform {
  transform(object, path): any {
    return map(object, path);
  }
}
