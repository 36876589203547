import { Pipe, PipeTransform } from '@angular/core';
import {find} from 'lodash';

@Pipe({
  name: 'find'
})
export class FindPipe implements PipeTransform {

  transform<T>(value: Array<T>, path:any): T {
    return find(value,path) as T
  }

}
