import {Pipe, PipeTransform} from '@angular/core';
import {get} from 'lodash';

@Pipe({
  name: 'getProperty'
})
export class GetPropertyPipe implements PipeTransform {

  transform(object, path, defaultValue = null): any {
    return get(object, path, defaultValue);
  }

}
