import { Pipe, PipeTransform } from '@angular/core';
import { minBy} from 'lodash';

@Pipe({
  name: 'min'
})
export class MinPipe implements PipeTransform {

  transform(value: [any], path:string): any {
    return minBy(value,path);
  }

}
