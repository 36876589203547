import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService as NGXTranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import 'moment/locale/es';
import {BehaviorSubject} from 'rxjs'; // without this line it didn't work
@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
  /**
   * MomentPipe constructor
   * @param {TranslateService} translate
   */
  constructor(private translate: NGXTranslateService) {
  }

  /**
   * Make moment dynamic
   * @param {string} value
   * @param {string} format
   * @returns {any}
   */
  transform(value: moment.MomentInput, format?: string): any {
    // make the moment format configurable
    format = format ? format : 'MMMM Do YYYY';
    // get the initial value
    const initVal = moment(value).locale(moment.locale(this.translate.defaultLang)).format(format);
    // insert the value into a new behaviour subject. If the language changes, the behaviour subject will be
    // updated
    const momentObs = new BehaviorSubject<string>(initVal);
    this.translate.onLangChange.subscribe((lang) => {
      // format the new date according to the new locale
      const val = moment(value).locale(moment.locale(lang.lang)).format(format);
      momentObs.next(val);
    });
    return momentObs; // needs to be piped into the async pipe
  }

}
