import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateFormatPipe } from './date-format.pipe';
import { ImagePipe } from './image.pipe';
import { GetPropertyPipe } from './get-propery.pipe';
import { AsPipe } from '@shared/pipes/as.pipe';
import { MapPipe } from './map.pipe';
import { SumPipe } from '@shared/pipes/sum.pipe';
import { MinPipe } from './min.pipe';
import { DateDifferencePipe } from './date-difference.pipe';
import { FindPipe } from './find.pipe';
import {CalculateAmountPipe} from '@shared/pipes/calculate-amount.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { PluralizePipe } from './pluralize.pipe';
import { OrderByPipe } from './order-by.pipe';
import { FindLastPipe } from './find-last.pipe';
import { MaxByPipe } from './max-by.pipe';

@NgModule({
  declarations: [
    DateFormatPipe,
    ImagePipe,
    GetPropertyPipe,
    AsPipe,
    MapPipe,
    SumPipe,
    MinPipe,
    DateDifferencePipe,
    FindPipe,
    CalculateAmountPipe,
    SafeUrlPipe,
    PluralizePipe,
    OrderByPipe,
    FindLastPipe,
    MaxByPipe,
  ],
  exports: [
    DateFormatPipe,
    ImagePipe,
    GetPropertyPipe,
    AsPipe,
    MapPipe,
    SumPipe,
    MinPipe,
    DateDifferencePipe,
    FindPipe,
    CalculateAmountPipe,
    SafeUrlPipe,
    PluralizePipe,
    OrderByPipe,
    MaxByPipe,
  ],
  imports: [CommonModule],
})
export class PipesModule {}
