<ng-container [ngSwitch]="this.currentView">
  <ng-container *ngSwitchCase="'minified-view'">
    <ng-container [ngTemplateOutlet]="minifiedTemplate" *ngIf="!expanded">
    </ng-container>
    <ng-container *ngIf="this.expanded" [ngTemplateOutlet]="searchTemplate">
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchDefault [ngTemplateOutlet]="searchTemplate">
  </ng-container>
</ng-container>

<ng-template #minifiedTemplate>
  <div class="form-minified">
    <div class="form-minified-content" (click)="openDialogSearch()">
      <!--current dates selected-->
      <div class="form-minified__config">
        <span class="form-minified__date-field">
          <mat-icon>
          calendar_today
        </mat-icon>
          {{this.selected.startDate| dateFormat:'MMM D'|async}}
          <mat-icon>
          horizontal_rule
        </mat-icon>
          {{this.selected.endDate|dateFormat:'MMM D'|async}}
        </span>
        <!--num persons-->
        <span class="form-minified__people-field">
          <mat-icon>
          people
        </mat-icon>
          {{selectedPeople.numAdults.value}} {{'search.adults'|translate}}
        </span>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #searchTemplate>
  <div [formGroup]="this.formSearch" class="form search" >
    <div #container class="search__field">
      <div class="search__add-date">
        <button #dateTrigger="cdkOverlayOrigin" (click)="isOpenTemplateAddDates = !isOpenTemplateAddDates"
                cdkOverlayOrigin
                class="search__trigger" mat-button>
          <div class="search__label">
            <mat-icon>{{FORM_CONFIG.checkIn.iconName}}</mat-icon>
            {{this.selected.startDate| dateFormat:'ll'|async}}
            <mat-icon>
              horizontal_rule
            </mat-icon>
            {{this.selected.endDate|dateFormat:'ll'|async}}
          </div>
        </button>
      </div>
    </div>
    <div #containerPeople class="search__field">
      <div class="search__people">
        <button #peopleTrigger="cdkOverlayOrigin" (click)="this.openPeopleTemplate()" cdkOverlayOrigin
                class="search__trigger"
                mat-button>
          <div class="search__label">
            <mat-icon>{{FORM_CONFIG.numAdults.iconName}}</mat-icon>
            {{selectedPeople.numAdults.value}} {{'search.adults'|translate}}
            &nbsp;·&nbsp;
            {{selectedPeople.numChildren.value}} {{'search.children'|translate}}
          </div>
        </button>
      </div>
    </div>
    <button class="search__trigger search__submit" mat-button type="button" (click)="this.submitForm()">
      {{this.inPageHotel?('search.change'|translate):'search.label'|translate}}
    </button>
  </div>
  <!-- This template displays the overlay content and is connected to the button -->
  <ng-template
    (backdropClick)="isOpenTemplateAddDates=!isOpenTemplateAddDates"
    [cdkConnectedOverlayBackdropClass]="'notBackdrop'"
    [cdkConnectedOverlayDisableClose]="true"
    [cdkConnectedOverlayHasBackdrop]="isOpenTemplateAddDates"
    [cdkConnectedOverlayOffsetX]="-0"
    [cdkConnectedOverlayOffsetY]="10"
    [cdkConnectedOverlayOpen]="isOpenTemplateAddDates"
    [cdkConnectedOverlayOrigin]="dateTrigger"
    cdkConnectedOverlay
  >
    <div class="panel">
      <div class="select-range-date-container">
        <ngx-daterangepicker-material (choosedDate)="choseDate($event)" [autoApply]="true"
                                      [locale]="this.locale"
                                      [endDate]="this.selected?.endDate"
                                      [minDate]="this.selected?.minDate"
                                      [startDate]="this.selected?.startDate"
        >
        </ngx-daterangepicker-material>
      </div>
    </div>

  </ng-template>
  <!--people template-->
  <div dir="rtl">
    <ng-template
      (backdropClick)="isOpenTemplatePeople=!isOpenTemplatePeople"
      [cdkConnectedOverlayBackdropClass]="'notBackdrop'"
      [cdkConnectedOverlayDisableClose]="true"
      [cdkConnectedOverlayHasBackdrop]="isOpenTemplatePeople"
      [cdkConnectedOverlayOffsetX]="-0"
      [cdkConnectedOverlayOffsetY]="10"
      [cdkConnectedOverlayOpen]="isOpenTemplatePeople"
      [cdkConnectedOverlayOrigin]="peopleTrigger"
      cdkConnectedOverlay
    >
      <div class="panel">
        <div class="counter-people-container">
          <div class="counter-field">
            <span class="counter-text-description">{{'search.adults'|translate}}</span>
            <button (click)="counter(this.selectedPeople.numAdults.key,'subtract')" class="counter-button" mat-button>-
            </button>
            {{selectedPeople.numAdults.value}}
            <button (click)="counter(this.selectedPeople.numAdults.key,'add')" class="counter-button" mat-button>+</button>
          </div>
          <div class="counter-field">
            <span class="counter-text-description">{{'search.children'|translate}}</span>
            <button (click)="counter(this.selectedPeople.numChildren.key,'subtract')" class="counter-button" mat-button>-
            </button>
            {{selectedPeople.numChildren.value}}
            <button (click)="counter(this.selectedPeople.numChildren.key,'add')" class="counter-button" mat-button>+
            </button>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</ng-template>


