import {Pipe, PipeTransform} from '@angular/core';
import {environment} from '../../../environments/environment';

@Pipe({
  name: 'image'
})
export class ImagePipe implements PipeTransform {

  transform(url: string): string {
    if (url){
      return environment.hostStrapi + url;
    }
    return '/assets/img/default_image.png'
  }

}
