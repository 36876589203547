// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  isServer: false,
  // for prerender
  host: 'http://localhost:4000',
  apiHost: 'https://api.lostambos.com.pe/api/',
  // apiHost: 'http://localhost:8000/api/',
  // apiHostStrapi: 'https://app.lostambos.com.pe/api/',
  apiHostStrapi: 'http://localhost:1337/api/',
  hostStrapi: 'https://app.lostambos.com.pe',
  tokenStrapi: '00de03fd39604162d794d74848eb36b0e9abca9b5e07a776ef42853db16de801aeb7868aeb7c3864ccad1868cf9a3df2dd008d8b706954880c18f43fef656ba2d1d907aad0658d10802253f899393789477b10a4a169c9eb0890e2a16d98461d5328075c4f290fa3b4d98540b7bb185e8d9d734a94eb99c9846fc3041a75f1a2'
};
