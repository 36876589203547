import {Component, Input, OnInit} from '@angular/core';
import {SessionStorage} from 'ngx-webstorage';
import {HotelModel} from '@shared/models/response-strapi-many-data';

@Component({
  selector: 'app-spinner-loading',
  templateUrl: './spinner-loading.component.html',
  styleUrls: ['./spinner-loading.component.scss'],
})
export class SpinnerLoadingComponent implements OnInit {
  @SessionStorage('hotel') hotel: HotelModel;
  @Input() width: number = 100;
  colorGradient700 = '#AA7958';
  colorGradientPrimary = '#DEB37C'
  constructor() { }

  ngOnInit(): void {
    if (this.hotel) {
      this.colorGradient700 = this.hotel.attributes.colors['color-primary-600'];
      this.colorGradientPrimary = this.hotel.attributes.colors['color-primary'];
    }
  }

}
